
import React from "react";
import { useTranslation } from "react-i18next"
import HeroImg from "../../components/HeroImg/HeroImg";
import MainLayout from "../../layouts/MainLayout";
import "./aron.scss";
import { Link } from 'gatsby';

const Aron = () => {
  const { t } = useTranslation()
  return (
    <MainLayout metaTitle={t("aron:metaTitle")}>
      <>
        <HeroImg customClass="aron-hero" subtitle={t("aron:heroSubtitle")} customSubtitle="aron-subtitle" title={t("aron:heroTitle")} backgroundImage="bg/aron-hero.jpg">
          <p className="hero-text">{t("aron:heroText")}</p>
        </HeroImg>

        <div className="aron">

          <div className="aron-section-one-mobile">
            <h1 className="box aron-section-one-title">{t("aron:sectionOneTitle")}</h1>
            <p className="box">{t("aron:sectionOneTextOne")}</p>
            <p className="box">{t("aron:sectionOneTextTwo")}</p>
            <p className="box">{t("aron:sectionOneTextThree")}</p>
            <p>{t("aron:sectionOneTextFour")}</p>
          </div>
          <HeroImg customClass="aron-section-one" backgroundImage="bg/aron-section-one.jpg">
            {<div className="aron-section-one-container">
              <h1 className="box aron-section-one-title">{t("aron:sectionOneTitle")}</h1>
              <p className="box">{t("aron:sectionOneTextOne")}</p>
              <p className="box">{t("aron:sectionOneTextTwo")}</p>
              <p className="box">{t("aron:sectionOneTextThree")}</p>
              <p>{t("aron:sectionOneTextFour")}</p>
            </div>}
          </HeroImg>

          <HeroImg customClass="aron-section-two" backgroundImage="bg/aron-section-two.jpg">
            {<div className="aron-section-two-container">
              <h4>{t("common:organoleptic")}</h4>
              <h3 className="box">{t("common:properties")}</h3>
              <div className="hide-on-mobile">
                <h4>{t("common:color")}</h4>
                <p className="box-two">{t("aron:sectionTwoTextOne")}</p>
                <h4>{t("common:aroma")}</h4>
                <p>{t("aron:sectionTwoTextTwo")}</p>
                <p>{t("aron:sectionTwoTextThree")}</p>
                <p className="box-two">{t("aron:sectionTwoTextFour")}</p>
                <h4>{t("common:palate")}</h4>
                <p>{t("aron:sectionTwoTextFive")}</p>
                <p className="box-two">{t("aron:sectionTwoTextSix")}</p>
                <h4>{t("common:aftertaste")}</h4>
                <p>{t("aron:sectionTwoTextSeven")}</p>
                <p>{t("aron:sectionTwoTextEight")}</p>
              </div>
            </div>}
          </HeroImg>
          <div className="aron-section-two-mobile">
            <h4>{t("common:color")}</h4>
            <p className="box-two">{t("aron:sectionTwoTextOne")}</p>
            <h4>{t("common:aroma")}</h4>
            <p>{t("aron:sectionTwoTextTwo")}</p>
            <p>{t("aron:sectionTwoTextThree")}</p>
            <p className="box-two">{t("aron:sectionTwoTextFour")}</p>
            <h4>{t("common:palate")}</h4>
            <p>{t("aron:sectionTwoTextFive")}</p>
            <p className="box-two">{t("aron:sectionTwoTextSix")}</p>
            <h4>{t("common:aftertaste")}</h4>
            <p>{t("aron:sectionTwoTextSevenMobile")}</p>
          </div>

          <HeroImg customClass="aron-section-three" backgroundImage="bg/aron-section-three.jpg">
            {
              <div className="aron-section-three-container">
                <h4>{t("aron:sectionThreeTitle")}</h4>
                <h3 className="differente-style box"><span>{t("common:pairing")}</span>{t("common:food")}</h3>
                <div className="hide-on-mobile">
                  <h4 className="differente-style-two">{t("aron:sectionThreeTextOne")}</h4>
                  <p className="box-two">{t("aron:sectionThreeTextTwo")}</p>
                  <h4>{t("aron:sectionThreeTextThree")}</h4>
                  <p>{t("aron:sectionThreeTextFour")}</p>
                  <p className="box-two">{t("aron:sectionThreeTextFive")}</p>
                  <h4>{t("aron:sectionThreeTextSix")}</h4>
                  <p className="box-two">{t("aron:sectionThreeTextSeven")}</p>
                  <h4>{t("aron:sectionThreeTextEight")}</h4>
                  <p>{t("aron:sectionThreeTextNine")}</p>
                  <p>{t("aron:sectionThreeTextTen")}</p>
                  <p>{t("aron:sectionThreeTextEleven")}</p>
                </div>
              </div>
            }
          </HeroImg>
          <div className="aron-section-three-mobile">
            <h4 className="differente-style-two">{t("aron:sectionThreeTextOne")}</h4>
            <p className="box-two">{t("aron:sectionThreeTextTwo")}</p>
            <h4>{t("aron:sectionThreeTextThree")}</h4>
            <p>{t("aron:sectionThreeTextFour")}</p>
            <p className="box-two">{t("aron:sectionThreeTextFive")}</p>
            <h4>{t("aron:sectionThreeTextSix")}</h4>
            <p className="box-two">{t("aron:sectionThreeTextSeven")}</p>
            <h4>{t("aron:sectionThreeTextEight")}</h4>
            <p>{t("aron:sectionThreeTextNine")}</p>
            <p>{t("aron:sectionThreeTextTen")}</p>
            <p>{t("aron:sectionThreeTextEleven")}</p>
          </div>
        </div>
        <HeroImg customClass="aron-section-four" backgroundImage="bg/bg-bottle.jpg">
          {
            <div className="aron-section-four-container">
              <h2>{t("common:otherBrandies")}</h2>
              <Link to={t("routes:gordaApricot")}><img src="/img/apricot-small.png" alt="apricot" /></Link>
              <Link to={t("routes:gordaQuince")}><img src="/img/quince-small.png" alt="quince" /></Link>
              <Link to={t("routes:gordaPlum")}><img src="/img/plum-small.png" alt="plum" /></Link>       
            </div>
          }
        </HeroImg>
      </>
    </MainLayout>
  )
}

export default Aron;